define("discourse/plugins/stemaway-project-generation/discourse/services/ai-mentor-service", ["exports", "@ember/service", "discourse/lib/ajax", "@ember/object"], function (_exports, _service, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    router: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    siteSettings: (0, _service.inject)(),
    topicId: null,
    displayCodeAlongButton: false,
    firstGreeting: true,
    init() {
      this._super(...arguments);
      this._updateValuesBasedOnRoute();
      this.router.on('routeDidChange', () => {
        this._updateValuesBasedOnRoute();
      });
    },
    _updateValuesBasedOnRoute() {
      this.loadActivatedTopics();
      this.set('topicId', this.get('router.currentRoute.parent.params.id'));
      this.checkIfTopicInCodeAlongCategory();
    },
    get username() {
      return this.currentUser.username;
    },
    setFirstGreeting(greet) {
      this.firstGreeting = greet;
    },
    checkIfTopicInCodeAlongCategory() {
      return new Promise((resolve, reject) => {
        let codeAlongCategoryId = this.siteSettings.code_along_category;
        if (!codeAlongCategoryId) {
          this.set('displayCodeAlongButton', false);
          return resolve();
        }
        if (!this.topicId) {
          this.set('displayCodeAlongButton', false);
          return resolve();
        }
        (0, _ajax.ajax)(`/t/${this.topicId}`).then(topicDetails => {
          if (topicDetails.category_id.toString() === codeAlongCategoryId.toString()) {
            this.set('displayCodeAlongButton', true);
          } else {
            this.set('displayCodeAlongButton', false);
          }
          resolve();
        }).catch(error => {
          this.set('displayCodeAlongButton', false);
          reject(error);
        });
      });
    },
    loadActivatedTopics() {
      const activatedTopics = localStorage.getItem('activatedTopics');
      this.set('activatedTopics', activatedTopics ? JSON.parse(activatedTopics) : {});
    },
    saveActivatedTopics() {
      localStorage.setItem('activatedTopics', JSON.stringify(this.activatedTopics));
    },
    isFirstActivation(topicId) {
      const username = this.currentUser.username;
      const userTopics = this.activatedTopics[username] || {};
      return !userTopics[topicId];
    },
    markTopicActivated(topicId) {
      const username = this.currentUser.username;
      const userTopics = this.activatedTopics[username] || {};
      userTopics[topicId] = true;
      this.activatedTopics[username] = userTopics;
      this.saveActivatedTopics();
    },
    deductPointsForHelpMode(topic) {
      const username = this.currentUser.username;
      const pointsToSubtract = this.siteSettings.ai_mentor_tacks_cost;
      const data = {
        username,
        points: pointsToSubtract,
        reason: `AI Mentor (Topic - ${topic})`
      };
      return (0, _ajax.ajax)("/stemaway-project-generation/subtract-user-points", {
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json"
      }).catch(error => {
        console.error("Failed to deduct points: ", error);
      });
    }
  });
});