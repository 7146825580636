define("discourse/plugins/stemaway-project-generation/discourse/connectors/discovery-above/coming-soon-message", ["exports", "@ember/component", "@ember/service", "@ember/template"], function (_exports, _component, _service, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    appEvents: (0, _service.inject)(),
    message: '',
    isVisible: false,
    init() {
      this._super(...arguments);
      let studentLinks = this.siteSettings.show_student_links.split(',').map(Number);
      let industryLinks = this.siteSettings.show_instructor_links.split(',').map(Number);
      this.linkDetails = {
        student: [{
          id: studentLinks[0],
          label: 'Sign Up',
          icon: 'fa-user-plus'
        }, {
          id: studentLinks[1],
          label: 'Calendar',
          icon: 'fa-calendar'
        }, {
          id: studentLinks[2],
          label: 'Tools & Programs',
          icon: 'fa-wrench'
        }, {
          id: studentLinks[3],
          label: 'FAQ',
          icon: 'fa-question-circle'
        }],
        industry: [{
          id: industryLinks[0],
          label: 'Sign Up',
          icon: 'fa-user-plus'
        }, {
          id: industryLinks[1],
          label: 'Calendar',
          icon: 'fa-calendar'
        }, {
          id: industryLinks[2],
          label: 'Tools & Programs',
          icon: 'fa-wrench'
        }, {
          id: industryLinks[3],
          label: 'FAQ',
          icon: 'fa-question-circle'
        }]
      };
      this.appEvents.on('ai-chat:show-student-links', this, 'prepareShowStudentLinks');
      this.appEvents.on('ai-chat:show-industry-links', this, 'prepareShowIndustryLinks');
      this.appEvents.on('ai-chat:hide-message', this, 'hideMessage');
    },
    willDestroyElement() {
      this._super(...arguments);
      this.appEvents.off('ai-chat:show-student-links', this, 'prepareShowStudentLinks');
      this.appEvents.off('ai-chat:show-industry-links', this, 'prepareShowIndustryLinks');
      this.appEvents.off('ai-chat:hide-message', this, 'hideMessage');
    },
    prepareShowStudentLinks() {
      if (this.isVisible) {
        this.hideMessage(() => this.showStudentLinks());
      } else {
        this.showStudentLinks();
      }
    },
    prepareShowIndustryLinks() {
      if (this.isVisible) {
        this.hideMessage(() => this.showIndustryLinks());
      } else {
        this.showIndustryLinks();
      }
    },
    showStudentLinks() {
      const linksHtml = this.linkDetails.student.map(link => `
      <div class="link-item-homepage"><a href="/t/${link.id}" target="_blank"><i class="fa ${link.icon}"></i> ${link.label}</a></div>
    `).join('');
      this.updateMessage(linksHtml);
    },
    showIndustryLinks() {
      const linksHtml = this.linkDetails.industry.map(link => `
      <div class="link-item-homepage"><a href="/t/${link.id}" target="_blank"><i class="fa ${link.icon}"></i> ${link.label}</a></div>
    `).join('');
      this.updateMessage(linksHtml);
    },
    updateMessage(htmlContent) {
      this.set('message', (0, _template.htmlSafe)(htmlContent));
      setTimeout(() => this.set('isVisible', true), 5);
    },
    hideMessage(callback) {
      this.set('isVisible', false);
      setTimeout(() => {
        this.set('message', '');
        if (typeof callback === 'function') {
          callback.call(this);
        }
      }, 400);
    }
  });
});