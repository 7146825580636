define("discourse/plugins/stemaway-project-generation/discourse/connectors/topic-navigation/ai-mentor", ["exports", "@ember/component", "discourse/lib/ajax", "@ember/service", "@ember/runloop", "@ember/object"], function (_exports, _component, _ajax, _service, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    evaluationService: (0, _service.inject)('ai-mentor-service'),
    chatStateManager: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    isLoading: false,
    chatVisible: false,
    messages: [],
    userInput: '',
    stemawayUser: null,
    userPoints: null,
    chatCollapsed: false,
    lastMessageWasCustomHelpPrompt: false,
    isLoadingResponse: false,
    isHelpModeActive: false,
    awaitingConfirmationForHelp: false,
    firstHelpRequest: true,
    conversationHistory: [],
    init() {
      this._super(...arguments);
      this.evaluationService.checkIfTopicInCodeAlongCategory().then(() => {
        if (!this.get('evaluationService.displayCodeAlongButton')) {
          return;
        }
        this.topicId = this.get('router.currentRoute.parent.params.id');
        this.updateUserPoints();
        const chatVisible = localStorage.getItem('chatVisible') === 'true';
        const chatCollapsed = true;
        this.setProperties({
          chatVisible: chatVisible,
          chatCollapsed: chatCollapsed
        });
        if (this.get('currentUser')) {
          (0, _ajax.ajax)('/users/stemaway.json').then(response => {
            this.set('stemawayUser', response.user);
          });
        }
        this.drawerActiveObserver();
        window.addEventListener('resize', () => {
          const isDrawerActive = this.get('chatStateManager.isDrawerActive');
          this.adjustChatInterfaceForDrawer(isDrawerActive);
        });
        this.observeRouteChange();
      }).catch(error => {
        console.error('Error checking code along category:', error);
      });
    },
    drawerActiveObserver: (0, _object.observer)('chatStateManager.isDrawerActive', function () {
      const isDrawerActive = this.get('chatStateManager.isDrawerActive');
      this.adjustChatInterfaceForDrawer(isDrawerActive);
    }),
    evaluationCompleteObserver: (0, _object.observer)('evaluationComplete', function () {
      if (this.evaluationComplete) {
        this.resetEvaluation();
      }
    }),
    observeRouteChange: (0, _object.observer)('router.currentRouteName', function () {
      this.topicId = this.get('router.currentRoute.parent.params.id');
      this.conversationHistory = [];
      this.get('messages').clear();
      this.loadConversationHistory();
      this.evaluationService.setFirstGreeting(true);
    }),
    adjustChatInterfaceForDrawer(isActive) {
      const isDesktop = window.innerWidth >= 860;
      const chatInterface = document.querySelector('.ai-chat-interface-topics-mcq');
      if (!chatInterface) {
        return;
      }
      if (!isDesktop) {
        chatInterface.style.right = '17px';
        return;
      }
      if (isActive) {
        chatInterface.style.right = '450px';
      } else {
        chatInterface.style.right = '17px';
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      window.removeEventListener('resize', this.adjustChatInterfaceForDrawer);
    },
    scrollToBottom() {
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        const chatMessagesContainer = document.querySelector('.chat-messages-topics-mcq');
        if (chatMessagesContainer) {
          chatMessagesContainer.lastElementChild.scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    },
    fetchTopicTitle(topicId) {
      return (0, _ajax.ajax)(`/t/${topicId}.json`).then(response => {
        return response.title;
      }).catch(error => {
        console.error('Error fetching topic:', error);
      });
    },
    fetchTopicContent(userQuery) {
      (0, _ajax.ajax)(`/stemaway-project-generation/fetch-topic-content/${this.topicId}`).then(response => {
        if (response.error) {
          throw new Error(response.error);
        }
        let plainTextContent = response.content.replace(/<[^>]+>/g, '');
        let prompt = this.siteSettings.help_prompt.replace('{{plainTextContent}}', plainTextContent).replace('{{userQuery}}', userQuery);
        this.sendAPIRequest(prompt);
      }).catch(error => {
        console.error('Error fetching topic content');
        this.get('messages').pushObject({
          text: "Sorry, I couldn't fetch the topic content. Can you specify your query?",
          sender: 'ai'
        });
      });
    },
    updateUserPoints() {
      const username = this.get('currentUser.username');
      return (0, _ajax.ajax)("/stemaway-project-generation/fetch-user-points", {
        type: "GET",
        data: {
          username
        }
      }).then(response => {
        this.set('userPoints', response.points);
      });
    },
    sendAPIRequest(prompt) {
      let topic_data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.set('isLoadingResponse', true);
      let model = `main`;
      let temperature = 0;
      let request_prompt = `\nTopic Data: \n${prompt}`;
      if (!topic_data) {
        request_prompt = "";
      }
      let fullPrompt = this.conversationHistory.reduce((acc, exchange) => {
        return `${acc}\n${exchange.sender === 'user' ? 'User:' : 'AI:'} ${exchange.text}`;
      }, '') + `${request_prompt}`;
      const encodedPrompt = utf8ToBase64(fullPrompt);
      const encodedModel = utf8ToBase64(model);
      return (0, _ajax.ajax)("/stemaway-project-generation/make-api-request", {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          prompt: encodedPrompt,
          model: encodedModel,
          mcq: false
        })
      }).then(response => {
        this.set('isLoadingResponse', false);
        let botResponse = base64ToUtf8(response.trim());
        this.updateConversationHistory('ai', botResponse);
        let formattedResponse = this.formatCodeSnippet(botResponse);
        this.get('messages').pushObject({
          html: formattedResponse,
          sender: 'ai',
          isHtml: true
        });
        this.scrollToBottom();
      }).catch(error => {
        this.set('isLoadingResponse', false);
        this.get('messages').pushObject({
          text: "I'm sorry, I couldn't process your request. Please try again later.",
          sender: 'ai'
        });
      });
    },
    formatCodeSnippet(response) {
      let formattedResponse = response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      const sections = formattedResponse.split(/```/);
      return sections.map((section, index) => {
        if (index % 2 === 0) {
          return section.split('\n').map(line => `<span>${line}</span>`).join('');
        } else {
          let encodedSection = section.replace(/</g, '&lt;').replace(/>/g, '&gt;');
          return `<pre><code>${encodedSection}</code></pre>`;
        }
      }).join('');
    },
    updateConversationHistory(sender, text) {
      const timestamp = new Date().toISOString();
      const newMessage = {
        sender,
        text,
        timestamp
      };
      this.conversationHistory.push(newMessage);
      if (this.isHelpModeActive) {
        this.saveConversationHistory(newMessage);
      }
      if (this.conversationHistory.length > 5) {
        this.conversationHistory.shift();
      }
    },
    loadConversationHistory() {
      (0, _ajax.ajax)(`/stemaway-project-generation/fetch-conversation/${this.topicId}`).then(response => {
        this.conversationHistory = response.conversation;
        this.renderConversationHistory();
      }).catch(error => {
        console.error('Error loading conversation history:', error);
      });
    },
    renderConversationHistory() {
      this.get('messages').clear();
      this.conversationHistory.forEach(message => {
        let formattedResponse = this.formatCodeSnippet(message.text);
        this.get('messages').pushObject({
          html: formattedResponse,
          sender: message.sender,
          isHtml: true,
          timestamp: message.timestamp
        });
      });
      if (this.conversationHistory.length > 0) {
        const lastMessage = this.conversationHistory[this.conversationHistory.length - 1];
        const lastMessageTime = new Date(lastMessage.timestamp).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        });
        this.get('messages').pushObject({
          html: `<div class="last-message-time-topics-mcq">Last message at: ${lastMessageTime}</div>`,
          sender: 'system',
          isHtml: true
        });
      }
      this.scrollToBottom();
    },
    saveConversationHistory(newMessage) {
      const topicId = this.topicId;
      (0, _ajax.ajax)('/stemaway-project-generation/save-conversation', {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          topic_id: topicId,
          conversation: newMessage
        })
      }).catch(error => {
        console.error('Error saving conversation history:', error);
      });
    },
    checkGreeting() {
      this.scrollToBottom();
      if (this.evaluationService.firstGreeting) {
        let message = "Hello there! I am your AI Mentor. How can I assist you today?<br><span class='italic-text-topics-mcq'>Type 'help' to get started.</span>";
        this.get('messages').pushObject({
          html: message,
          sender: 'ai',
          isHtml: true
        });
        this.evaluationService.setFirstGreeting(false);
      }
    },
    actions: {
      startChat() {
        this.checkGreeting();
        this.set('chatVisible', true);
        this.set('chatCollapsed', false);
        localStorage.setItem('chatVisible', this.get('chatVisible'));
      },
      toggleCollapseChat() {
        this.checkGreeting();
        if (this.get('chatCollapsed')) {
          this.send('expandChat');
        } else {
          this.send('collapseChat');
        }
      },
      toggleChat() {
        this.checkGreeting();
        this.toggleProperty('chatVisible');
        localStorage.setItem('chatVisible', this.get('chatVisible'));
      },
      expandChatWithEvent(event) {
        event.stopPropagation();
        this.send('expandChat');
      },
      collapseChatWithEvent(event) {
        event.stopPropagation();
        this.send('collapseChat');
      },
      expandChat() {
        this.checkGreeting();
        this.set('chatCollapsed', false);
        localStorage.setItem('chatCollapsed', 'false');
      },
      collapseChat() {
        this.set('chatCollapsed', true);
        localStorage.setItem('chatCollapsed', 'true');
      },
      handleTextareaKeyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          this.send('sendMessage');
        }
      },
      sendMessage() {
        let message = this.get('userInput').trim();
        if (!message) {
          return;
        }
        this.get('messages').pushObject({
          text: message,
          sender: 'user'
        });
        this.scrollToBottom();
        this.set('userInput', '');
        if (message.toLowerCase() === "exit") {
          if (this.isHelpModeActive) {
            this.setProperties({
              isHelpModeActive: false,
              awaitingConfirmationForHelp: false,
              firstHelpRequest: true
            });
            this.get('messages').pushObject({
              text: "Exited help mode.",
              sender: 'ai'
            });
            this.conversationHistory = [];
            this.scrollToBottom();
            return;
          }
        }
        const responsePattern = /\b(yes|ya|yup|yeah)\b/;
        if (this.awaitingConfirmationForHelp && !responsePattern.test(message.toLowerCase())) {
          this.set('awaitingConfirmationForHelp', false);
          this.set('isHelpModeActive', false);
          this.set('firstHelpRequest', true);
          this.conversationHistory = [];
        }
        if (this.awaitingConfirmationForHelp && responsePattern.test(message.toLowerCase())) {
          if (this.evaluationService.isFirstActivation(this.topicId)) {
            this.updateUserPoints().then(() => {
              if (this.get('userPoints') < this.get('siteSettings.ai_mentor_tacks_cost')) {
                this.get('messages').pushObject({
                  text: "Insufficient tacks. Please try again later.",
                  sender: 'ai'
                });
                this.scrollToBottom();
                return;
              } else {
                this.evaluationService.deductPointsForHelpMode(this.topicId).then(() => {
                  this.evaluationService.markTopicActivated(this.topicId);
                });
                this.set('awaitingConfirmationForHelp', false);
                this.get('messages').pushObject({
                  html: "Okay, what do you need help with? <span class='italic-text-topics-mcq'>Help mode on. Type 'exit' to leave.</span>",
                  sender: 'ai',
                  isHtml: true
                });
                this.scrollToBottom();
                return;
              }
            });
          } else {
            this.set('awaitingConfirmationForHelp', false);
            this.get('messages').pushObject({
              html: "Okay, what do you need help with? <span class='italic-text-topics-mcq'>Help mode on. Type 'exit' to leave.</span>",
              sender: 'ai',
              isHtml: true
            });
            this.scrollToBottom();
            return;
          }
          return;
        }
        if (this.firstHelpRequest && !this.awaitingConfirmationForHelp && message.toLowerCase().includes('help')) {
          this.set('isHelpModeActive', true);
          this.conversationHistory = [];
          let customResponse = '';
          this.fetchTopicTitle(this.topicId).then(topicTitle => {
            if (this.evaluationService.isFirstActivation(this.topicId)) {
              customResponse = `Are you looking for help with "${topicTitle.replace(/:[^:\s]*(?:::[^:\s]*)*:/g, '')}"? <p class='italic-text-topics-mcq-cost'>One time cost of <span class = 'italic-text-topics-mcq-cost-items'> ${this.siteSettings.ai_mentor_tacks_cost} tacks </span> will be applied to your account for this topic.</p>`;
            } else {
              customResponse = `Are you looking for help with "${topicTitle.replace(/:[^:\s]*(?:::[^:\s]*)*:/g, '')}"? <p class='italic-text-topics-mcq-cost'>Topic access available based on previous purchase</p>`;
            }
            this.get('messages').pushObject({
              html: customResponse,
              sender: 'ai',
              isHtml: true
            });
            this.set('awaitingConfirmationForHelp', true);
            this.scrollToBottom();
            this.set('firstHelpRequest', false);
          });
          return;
        }
        if (this.isHelpModeActive && !this.awaitingConfirmationForHelp) {
          this.updateConversationHistory('user', message);
          this.fetchTopicContent(message);
          this.scrollToBottom();
          return;
        }
        let prompt = this.siteSettings.greeting_prompt.replace('{{message}}', message);
        this.updateConversationHistory('user', prompt);
        this.sendAPIRequest(prompt, false);
      },
      updateUserInput(value) {
        this.set('userInput', value);
      }
    }
  });
  function utf8ToBase64(str) {
    const utf8Bytes = new TextEncoder().encode(str);
    return btoa(String.fromCharCode(...utf8Bytes));
  }
  function base64ToUtf8(str) {
    const binaryString = atob(str);
    const binaryArray = Uint8Array.from(binaryString, char => char.charCodeAt(0));
    return new TextDecoder().decode(binaryArray);
  }
});